import React from 'react'
import Vyhalsenie from '../../assets/files/vyhlasenie2.pdf'
import frame from '../../assets/images/frame.png'


const TwoPercent = () => {
    return (
        <>
            <div className='content'>
                <h1 style={{marginBottom: '28px'}} >Darujte nám 2% z daní</h1>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center', paddingBottom: '32px'}}>
                <img src={ frame } style={{background: 'black', width: '350px', height: '350px'}}></img>
                </div>
                <p className={'files'} onClick={() => {
                    window.open(Vyhalsenie)
                }}>
                    <b>Vyhlásenie 2%</b>
                </p>
            </div>
        </>
    )
}

export default TwoPercent