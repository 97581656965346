import React from 'react'

const SponsorComponent = (props) => {

    const redirectHandler = () => {
        window.open(props.src, '_blank');
    }

    return (
        <div className={'sponsor-card'} onClick={redirectHandler}>
            <img src={ props.img } alt={'img'}/>
            <div>
                <h2>{props.sponsorName}</h2>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default SponsorComponent