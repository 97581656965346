import React from 'react'

const Support = ({iban, bank}) => {
    return (
        <div className={'green-box'}>
            <div className='content'>
                <div className="half-block">
                    <h5>{'Úspešní slabším'}</h5>
                    <p>
                        Oslovujeme úspešných podnikateľov, ktorým nie je ľahostajná situácia týchto ľudí a chcú sa zapojiť
                        do spolupráce s naším združením.
                    </p>
                </div>
                <div className="half-block">
                    <h5>{'IBAN'}</h5>
                    <p>
                        Ak Vás náš projekt oslovil, prosíme prispejte nám na číslo účtu<br/>
                        <b>{iban}</b> {bank}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Support