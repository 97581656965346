import React from 'react'
import ImageGallery from 'react-image-gallery'
import "react-image-gallery/styles/css/image-gallery.css";


const ActionItem = ({ header, subHeader, content, contentAfter, images}) => {
    return (
        <div className={'action-item'}>
            <div>
                <h2>{header}</h2>
                <h4>{subHeader}</h4>
                <div>{content}</div>
            </div>
                <ImageGallery items={images} />
            {contentAfter}
        </div>
    )
}

export default ActionItem