import React from 'react'
import VyrocnaSprava from '../../assets/files/vyrocna-sprava-FS-21-protected.pdf'
import { contact } from '../ContactPage/ContactPage'

const Publication = () => {

    return (
        <>
            <div className='content'>
                <h1 style={{marginBottom: '28px'}}>Zverejňovanie</h1>
                <div style={{ marginBottom: '48px' }}> 
                    <p>
                        V prípade, že je dokument chránený heslom, vyžiadajte si ho na emailovej adrese <b>{contact.email}</b>
                    </p>
                </div>
                <p className={'files'} onClick={() => {
                    window.open(VyrocnaSprava)
                }}>
                    <b>Vyročna sprava FŠ 2021</b>
                </p>
            </div>
        </>
    )
}

export default Publication