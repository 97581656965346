import React from 'react'

import HomeTitle from '../../components/standalones/HomeTitle'

const About = () => {
    return (
        <>
            <HomeTitle insideText={(<span>{'Takí sme sa'}<br/>{'narodili'}</span>)}/>
            <div className='content'>
                <div className={'jumbo'}> 
                <h1>Prečo sme vznikli</h1>
                <br/>
                <p style={{ textAlign: 'justify' }}>
                    Občianske združenie <strong>Farebný štvorlístok (FŠ)</strong> vzniklo v auguste 2020 na podnet rodičov <strong>zdravotne
                        znevýhodnených detí</strong>. Telesne, mentálne a zmyslovo postihnutí, bez ohľadu na diagnózu, nemajú
                    v meste priestor, kde by sa mohli denne stretávať s rovnocennými. Mnohé parky,  ihriská či
                    obchodné centrá v meste sú pre nich nevhodné, rušivé.
                    <br/><br/>
                    Farebný štvorlístok vznikol hlavne za účelom vytvorenia komunitného centra pre zdravotne znevýhodnené deti i dospelých, kde by sa mohli
                    nerušene spolu pohrať, tvoriť, zacvičiť, zaspievať, poradiť si alebo len tak posedieť, bez toho aby sa
                    neustále prispôsobovali okoliu. Toto miesto by taktiež slúžilo rodinám našich zdravotne postihnutých
                    - opatrovateľom, rodičom, asistentom či dobrovoľníkom, lebo aj oni sú kvôli svojmu poslaniu
                    odsunutí na okraj spoločnosti. 
                </p>
                </div> 
                <br/>
                <h4>Chceme miesto, kde môžu byť znevýhodnení sami sebou</h4>
                <p>
                    Mnohé nekoordinované pohyby alebo zvuky, ktoré patria medzi bežné prejavy ľudí s hendikepom, sú pre
                    zdravých ľudí desivé a rušivé. Naopak pre opatrovateľov, ktorí sú na to zvyknutí, je to bežné a
                    nevyvoláva to u nich zdesenie až odpor, ako často vídame v spoločnosti. Aj pre deti a ľudí
                    s hendikepom je skutočne dôležité byť súčasťou kolektívu – mnohé deti nemôžu navštevovať
                    základnú školu denne, majú upravený výchovno-vzdelávací proces. 
                </p>
                <br/>
                <h4>Myslíme aj na budúcnosť</h4>
                <p style={{ textAlign: 'justify' }}>
                    Po skončení povinnej školskej dochádzky často krát ostávajú doma na invalidnom dôchodku,
                    v denných stacionároch pre nich nie je voľné miesto. Návštevami centra v réžii Farebného štvorlístku
                    by mohli aj naďalej byť súčasťou komunity, vyhnúť sa stereotypu a izolácii v domácom prostredí.
                </p>
                <br/>
                <p style={{ textAlign: 'justify' }}>
                    Preto sme oslovili aj mesto <strong>Žilina</strong> a požiadali ich o spoluprácu pri vytvorení ihriska pre
                    mentálne a telesne postihnutých občanov mesta.
                </p>
                <hr/>
                <br/>
                <p style={{ textAlign: 'justify' }}>
                    Myšlienka komunity, súdržnosti a krásy v odlišnosti má svoju symboliku aj v názve Farebný štvorlístok
                    – každé z našich detí je iné, špecifické, odlišné svojimi vlastnosťami a diagnózami – každé má svoju
                    „farbu“. Štvorlístok všeobecne predstavuje šťastie, ale v našom ponímaní je to aj genetická odchýlka
                    ďateliny – a preto, každý z nás je iný a aj napriek našim diagnózam túžime po šťastí.
                </p>
            </div>
        </>
    )
}

export default About