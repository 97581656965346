import React, { useState } from 'react'
import { map } from 'lodash'
import { MENU, TEXT } from '../../utils/enums'
import { Link } from 'react-router-dom'
import logo from '../../assets/icons/logo.png'
import styled from 'styled-components'

const TopHelmet = styled.div`
    position: relative;
    width: 100%;
`

const HeaderWeb = styled.div`
    padding: 0 32px;
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    height: 60px;
    background: #268c3d;
    a {
        display: inline-flex;
        font-weight: 500;
        padding: 16px 32px;
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        &:hover {
            font-weight: 800;
        }
    }
    @media (max-width: 700px) {
        display: none
    }
`

const HeaderMobile = styled.div`
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    height: 60px;
    background: #268c3d;
    @media (min-width: 700px) {
        display: none
    }
`

const WebTitle = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 32px;
    text-transform: uppercase;
    img {
        height: 64px;
        width: 64px;
    }
    @media (max-width: 700px) {
        display: none
    }
`
const Hamburger = styled.div`
    padding: 8px;
    height: calc(100% - 16px);
    width: 50px;
    float: right;
    div {
        border-radius: 3px;
        display: flex;
        background-color: white;
        width: 70%;
        height: 10%;
        margin: 15% auto;
    }
`
const MobileBar = styled.div`
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    position: absolute;
    width: 100%;
    height: auto;
    top: ${props => props.isVisible ? '60px' : '-270px'};
    background-color: #1b622b;
    z-index: 2;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    a {
        display: flex;
        font-weight: 500;
        padding: 18px 32px;
        text-transform: uppercase;
        text-align: right;
        color: white;
        text-decoration: none;
        &:hover {
            font-weight: 800;
        }
    }
    @media (min-width: 700px) {
        display: none
    }
`
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
`

const TitlePrefix = styled.div`
    display: inline-flex;
    align-content: center;
    color: #6c757d;
    font-size: 18px;
    padding: 0px 32px 0px 32px;
    @media (max-width: 700px) {
        display: none
    }
`

const Title = styled.div`
    display: inline-flex;
    align-content: center;
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    @media (max-width: 700px) {
        text-align: left;
        font-size: 30px;
        padding: 0 16px
    }
`

const MobileTitle = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    padding: 8px 16px;
    font-size: 25px;
    color: white;
    font-weight: 500;
    img {
        background-color: white;
        padding: 4px;
        margin: 0 16px 0 0;
        width: 25px;
        height: 25px;
        border-radius: 16px;
    }
`


const Header = () => {
    const [showBar, setShowBar] = useState(false)

    const toggleMobileMenu = () => {
        setShowBar(!showBar)
    }
 
    return (
        <TopHelmet>
            <WebTitle>
                <img src={logo} alt={'logo'}/>
                <TitlePrefix>
                    {'Občianske'}<br/>{'združenie'}
                </TitlePrefix>
                <Title>
                    { TEXT.PAGE_NAME }
                </Title>
            </WebTitle>
            <HeaderWeb>
                {map(MENU, (item, index) => (
                    <Link to={item.PATH} key={ `link-${index}`}>{item.TEXT}</Link>
                ))}
            </HeaderWeb>
            <HeaderMobile>
                <MobileTitle>
                    <img src={logo} alt={'logo'}/>
                    { TEXT.PAGE_NAME }
                </MobileTitle>
                <Hamburger onClick={toggleMobileMenu}>
                    <div/><div/><div/>
                </Hamburger>
            </HeaderMobile>
            {showBar && <Overlay/>}
            <MobileBar isVisible={showBar}>
                {map(MENU, (item, index) => (
                    <Link to={item.PATH} onClick={toggleMobileMenu} key={ `link-2-${index}`}>{item.TEXT}</Link>
                ))}
            </MobileBar>
        </TopHelmet>
    )
}

export default Header