import React from 'react'
import { TEXT } from '../../utils/enums'
import Support from '../../components/standalones/Support'

const Footer = () => {
    return (
        <>
            <Support iban={TEXT.IBAN} bank={TEXT.BANK} />
            <footer>
                {TEXT.COPYRIGHT}
            </footer>
        </>
    )
}

export default Footer