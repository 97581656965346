import React from 'react'
import SponsorComponent from '../../components/standalones/Sponsor'
import Cartech from '../../assets/images/cartech.png'
import Horvat from '../../assets/images/horvat.png'

const Sponsors = () => {
    return (
        <>
            <div className='content'>
                <h1 style={{marginBottom: '28px'}} >Sponzori</h1>
                <SponsorComponent
                    sponsorName={'Cartech'}
                    description={'http://cartech.sk/'}
                    src={'http://cartech.sk/'}
                    img={Cartech}
                />
                <SponsorComponent
                    sponsorName={'Horvát'}
                    src={'https://horvat.sk/'}
                    description={'https://horvat.sk/'}
                    img={Horvat}
                />
            </div>
        </>
    )
}

export default Sponsors