import React from 'react'

export const contact = {
    email: 'farebnystvorlistok@gmail.com',
    phone: '0904 517 724',
    adress: 'Brodňanská 225/80, 01014 Žilina-Brodno',
    facebook: 'OZ Farebný štvorlístok'
}

const Contact = () => {
    return (
        <>
            <div className='content'>
                <h1 style={{marginBottom: '28px'}}>Kontakt</h1>
                <div>
                    <span><strong>Email: </strong> {contact.email} </span> <br/>
                    <span><strong>Mobil: </strong> {contact.phone} </span> <br/>
                    <span><strong>Adresa: </strong> {contact.adress} </span> <br/>
                    <span><strong>Facebook: </strong> <a target={'_blank'} href={'https://www.facebook.com/groups/httpswww.farebnystvorlistok.sk'}>{contact.facebook} </a></span> <br/>
                </div>
            </div>
        </>
    )
}

export default Contact