import React from 'react'
import { map, get } from 'lodash'
import field from '../../assets/images/ihrisko.jpg'
import field2 from '../../assets/images/ihrisko1.jpg'
import field3 from '../../assets/images/ihrisko2.jpg'
import field4 from '../../assets/images/ihrisko3.jpg'
import field5 from '../../assets/images/ihrisko4.jpg'
import field6 from '../../assets/images/ihrisko5.jpg'
import field7 from '../../assets/images/ihrisko6.jpg'
import pic3 from '../../assets/images/pic3.jpg'
import pic5 from '../../assets/images/pic5.jpg'
import sol from '../../assets/images/sol.jpg'
import sol1 from '../../assets/images/sol2.jpg'
import so3 from '../../assets/images/sol3.jpg'
import sol4 from '../../assets/images/sol4.jpg'
import sol5 from '../../assets/images/sol5.jpg'

import ActionItem from './components/ActionItem'

const images = [
    { renderItem: () => <img src={field} height={'500px'} />, thumbnail: field },
    { renderItem: () => <img src={field2} height={'500px'} />, thumbnail: field2 },
    { renderItem: () => <img src={field3} height={'500px'} />, thumbnail: field3 },
    { renderItem: () => <img src={field4} height={'500px'} />, thumbnail: field4 },
    { renderItem: () => <img src={field5} height={'500px'} />, thumbnail: field5 },
    { renderItem: () => <img src={field6} height={'500px'} />, thumbnail: field6 },
    { renderItem: () => <img src={field7} height={'500px'} />, thumbnail: field7 },
];

const images1 = [
    { renderItem: () => <img src={pic3} height={'500px'}/>, thumbnail: pic3 },
    { renderItem: () => <img src={pic5} height={'500px'}/>, thumbnail: pic5 },
    { renderItem: () => <img src={sol} height={'500px'}/>, thumbnail: sol },
    { renderItem: () => <img src={sol1} height={'500px'}/>, thumbnail: sol1 },
    { renderItem: () => <img src={so3} height={'500px'}/>, thumbnail: so3 },
    { renderItem: () => <img src={sol4} height={'500px'}/>, thumbnail: sol4 },
    { renderItem: () => <img src={sol5} height={'500px'}/>, thumbnail: sol5 }
];

const Actions = () => {

    const actions = [
        {
            header: 'Inkluzívne ihrisko -  Vlčince',
            content: '',
            contentAfter: (null),
            images: images
        },
        {
            header: 'Centrum na Solinkách',
            content: (
                <>
                    <p>Mestský úrad v Žiline odbor sociálnych vecí nám poskytol bezbarierový priestor. Od novembra 2021 sa stretávame každú <b>stredu 9-12 hod.</b> a <b>štvrtok 13-17 hod.</b> v dennom centre Solinky na Borovej ulici v Žiline.</p>
                    <p>Centrum slúži pre deti aj pre rodičov a opatrovateľov. <b>Poskytujeme tiež poradenstvo pri komunikácii s úradmi.</b></p>
                </>
            ),
            contentAfter: (null
            ),
            images: images1
        }
    ]

    return (
        <>
            <div className='content'>
                <div className={'jumbo'}> 
                    <p style={{ textAlign: 'justify' }}>
                    V spolupráci s pracovníkmi <b>Mestského úradu Žilina</b> a pani poslankyňou 
                    <b>Mgr. et Mgr. Janou Filipovou</b> vzniklo v Žiline na sídlisku Vlčince prvé 
                    bezbariérové inkluzívne ihrisko
                    </p>
                </div>
                <br/>
                <div>
                    {map(actions, (item, key) => (
                        <>
                            <ActionItem 
                                header={ get(item, 'header') }
                                subHeader= { get(item, 'subHeader') }
                                content={ get(item, 'content') }
                                contentAfter={ get(item, 'contentAfter') }
                                images={ get(item, 'images') }
                                key={ `action-item-${key}` }
                            />
                            <br/>
                        </>
                    ))}
                </div>
                <br/>
            </div>
        </>
    )
}

export default Actions