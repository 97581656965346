import React from 'react'
import styled from 'styled-components'
import img from '../../assets/images/jumbo.jpg'
import imgMobile from '../../assets/images/jumboMobile.jpg'
import jumboMobileSecond from '../../assets/images/jumboMobileSecond.jpg'

const TitlePicture = styled.div`
    position: relative;
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    box-shadow: 0px 0px 20px grey;
    @media (max-width: 700px) {
        background-image: url(${imgMobile});
    }
    @media (max-width: 550px) {
        background-image: url(${jumboMobileSecond});
    }
`

const TitlePictureText = styled.div`
    padding: 24px 40px 24px 40px;
    display: flex;
    text-align: right;
    position: absolute;
    font-size: 60px;
    font-weight: bold;
    color: white;
    border-radius: 10px;
    top: 15%;
    right: 10%;
    @media (max-width: 700px) {
        font-size: 50px;
        padding: 0;
    }
`

const HomeTitle = ({ insideText }) => {
    return (
        <TitlePicture>
            <TitlePictureText>
                { insideText }
            </TitlePictureText>
        </TitlePicture>
    )
}

export default HomeTitle